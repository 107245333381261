/*********************************** Global ***********************************/

body {
  background-color: white !important;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  border: none;
  outline: none;
}
body {
  background-color: #DAE1E9;
  color: #000000;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Source Sans Pro' !important;
  color: #555 !important;
}

hr {
  margin-left: 70px;
  margin-right: 70px;
}

table {
  border-width: 0px;
}

td, th {
  padding: 5px;
  font-size: 0.8rem;
}

label {
  margin: 8px;
}

.theme-color {
  color: #0F8CA2 !important;
}

.btn-theme-color {
  color: white !important;
  background-image: linear-gradient(to bottom right, #1ECAD3, #0077AF)!important;
}

.bold {
  font-weight: bold;
  font-size: 1.02rem;
}

.blue-underline {
  background-color: #1ECAD3;
  min-height: 5px;
  height: 5px;
  width: 430px;
}

.subtitle {
  font-family: 'Source Sans Pro' !important;
  color: #444 !important;
  font-size: 1.2rem;
}

.mw-60p {
  max-width: 60%;
  margin: auto;
}

.subtitle-small {
  font-family: 'Source Sans Pro' !important;
  color: #444 !important;
  font-size: 1.3rem;
}

.subtitle-xsmall {
  font-family: 'Source Sans Pro' !important;
  color: #444 !important;
  font-size: 0.9rem;
}

.navbar-gradient-background{
  background-image: linear-gradient(to bottom right, #FF9E18, #F36A31);
}

.nav-title {
  font-weight: bold;
}

.nav-link {
  font-size: 1.0rem !important;
}

.nav-link {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.nav-link:hover {
  border-color: #FFF #FFF #DAE1E9 !important;
}

.nav-link.active {
  border-color: #FFF #FFF #0F8CA2 !important;
}

.fork-me {
  z-index: 99999;
}

.separator {
  border-left: 2px solid #0F8CA2;
  padding-left: 10px;
}

.input-width {
  width: 300px !important;
}

.footer {
  width: 100%;
  padding: 5px;
  text-decoration: underline;
}

.form-group {
  padding-top: 0px;
}

/*********************************** Landing **********************************/

.landing-icon {
  width: 80px;
  height: 80px;
  margin-top: 50px;
}

.landing-title {
  font-size: 2rem;
  font-weight: bold;
}

.landing-button {
  margin-top: 30px;
}

.landing-bottom-gutter {
  margin-bottom: 50px;
}

/********************************* Generator **********************************/

.border1pxsolid0F8CA2{
  border: 1px solid #0F8CA2;
}

input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  padding: 3px 6px;
  border: 1px solid #ddd;
  display: inline-block;
  color: #000;
  cursor: pointer;
}
input[type="radio"]:checked + label {
  background-color: #0F8CA2;
  color: #fff;
  border-color: #0F8CA2;
}

.qr-code {
  width: 180px;
  height: auto;
  display: block;
  margin: auto;
}

.start-hidden {
  display: none;
}

.digital-link {
  color: #999;
  font-size: 1.2rem;
  font-weight: bold;
  word-wrap: break-word;
  width: 560px;
  border: solid 1px #DAE1E9;
  border-radius: 3px;
}

.digital-link:focus {
  outline: solid 2px #0F8CA2!important;
}

.italic {
  font-style: italic;
}

.light-grey {
  color: #AAA;
}

.right-col-top-margin {
  margin-top: 100px;
}

.tall-dropdown {
  height: auto !important;
  overflow: visible !important;
}

.button-small {
  margin: auto;
}

.btn-fixed-height {
  height: 45px !important;
}

.slider-container {
  display: flex;
  align-items: center;
}

.radio-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

#slider {
  -webkit-appearance: none;
  width: 200px;
  height: 10px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-left: 10px;
}

#slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0F8CA2;
  cursor: pointer;
}

#slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0F8CA2;
  cursor: pointer;
}

#slider-value {
  margin-left: 10px;
}

input[type="checkbox"]:checked {
  background-color: #0F8CA2!important;
}
/********************************** Verifier **********************************/

.verifier-verdict {
  font-family: 'Source Sans Pro' !important;
  color: #444 !important;
  font-weight: bold;
  font-size: 1.7rem !important;
}

.deprecated-message {
  font-family: 'Source Sans Pro' !important;
  color: #444 !important;
  font-weight: 200;
  font-size: 1rem !important;
}

.verifier-query {
  width: 550px !important;
  margin: auto;
}

.grammar {
  white-space:pre-wrap;
  font-family: monospace !important;
}

.verdict-icon {
  width: 40px;
  height: 40px;
}

.deprecated-icon {
  width: 27px;
  height: 27px;
}

.verdict-icon-small {
  width: 18px;
  height: 18px;
}

.qr-choice {
  width: 300px !important;
  margin: auto;
}

.verifier-results-container {
  max-width: 100vw;
  max-height: 100vh;
  margin-right: 10px;
  margin-top: 10px;
}

.scrollx {
  overflow-x: auto;
}

/************************************ Scan ************************************/

.scan-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.scan-description {
  font-size: 1rem;
}

.box-tile {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #EEE;
  margin: 5px;
  width: 100px;
  height: 120px;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
}

.box-label {
  font-weight: bold;
  font-size: 0.8rem;
}

.box-icon {
  height: 35px;
  width: auto;
}

.scan-product-image {
  width: 150px;
  height: 150px;
}

.text-deco-none{
  text-decoration:none!important;
}

/***************************** Mobile overrides  ******************************/

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 1.2rem;
  }

  .blue-underline {
    background-color: #1ECAD3;
    min-height: 5px;
    height: 5px;
    width: 330px;
  }

  .right-col-top-margin {
    margin-top: 20px;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .mw-60p {
    max-width: 90%;
    margin: auto;
  }

  .subtitle-wrapper {
    max-width: 90%;
    margin: auto;
  }

  .verifier-query {
    width: 350px !important;
  }

  .separator {
    border-left: 0px;
    border-top: 2px solid #BBB;
    padding-left: 0px;
    margin-top: 20px;
  }

  .separator-landing {
    border: 0;
  }

  #input_verifier_query {
    font-size: 1rem;
  }

  .landing-bottom-gutter {
    margin-bottom: 20px;
  }

  .fork-me {
    height: 100px;
    width: auto;
  }

  .input-width {
    width: 320px !important;
  }

  .digital-link {
    width: 360px;
  }
}

/********************** Bootstrap post-render overrides ***********************/

.bmd-form-group {
  padding-top: 0px !important;
}
